@import url('../var.css');

.container {
  width: 75%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  /* border: 1px solid #5a1363; */
}
@media screen and (max-width: 768px) {
  .container {
    width: 95%;
  }
}
