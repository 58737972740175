@import url('../var.css');

.container {
  /* overflow: auto; */
  width: 500px;
  height: 100%;
  background-color: var(--colour-tertiary);
  position: fixed;
  top: 0;
  right: -500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: right 0.3s ease-in-out;
  z-index: 1;
  .openFormBtn {
    position: absolute;
    width: 53.2px;
    height: 53.2px;
    top: 0px;
    left: -53px;
    background-color: var(--colour-tertiary);
    color: #f5f5f5;
    padding: 0.3rem 0.9rem;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    border-right: none;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  &.active {
    top: 0;
    right: 0px;
    overflow: auto;
    .openFormBtn {
      top: 0;
      left: 0px;
      background-color: var(--colour-tertiary);
    }
  }
}
.header {
  width: 100%;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--colour-primary);
  font-weight: bold;
}
.main {
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  /* flex: 1; */
  .form {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem !important;
    width: 100%;

    input,
    textarea,
    select {
      padding: 0.5rem;
      font-family: inherit;
      border: none;
      outline: none;
      border-radius: 5px;
      box-sizing: border-box;
      color: var(--colour-secondary);
      &::placeholder {
        color: var(--colour-secondary);
      }
    }
  }
}

.form div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  label {
    color: var(--colour-primary);
  }
}
.form input {
  width: 100%;
}
.form textarea {
  width: 100%;
  resize: none;
  height: 200px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    translate: 100px;
  }
  to {
    opacity: 1;
    translate: 0px;
  }
}

.form .extra {
  padding: 0.5rem;
  padding-left: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
  animation: fadeIn 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  label {
    color: var(--colour-primary);
  }
}

.footer {
  width: 100%;
  /* flex: 1; */
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  & .addicon {
    width: 1.5rem;
    height: 1.5rem;
    color: var(--colour-extra1);
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    top: 0;
    right: -100%;
    border-radius: 0;
  }
  .active {
    top: 0;
    right: 0px;
  }
  .container .openFormBtn {
    top: 0;
    left: -53px;
    transition: left 0.5s ease-in-out;
  }
  .container.active .openFormBtn {
    top: 0;
    left: 0px;
    background-color: transparent;
  }
}
