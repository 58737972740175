@import url('../var.css');

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--colour-primary);
  border: 1px solid var(--colour-primary);
  border-radius: 10px;
  padding: 10px 10px;
  box-sizing: border-box;
  line-height: 1;
  gap: 0.5rem;
  text-align: left;
  color: var(--text-unfocus);
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  p {
    font-weight: bold;
    color: var(--text-unfocus);
    margin-left: 0.5rem;
  }
  .icon {
    color: var(--colour-secondary);
  }
}
.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  gap: 0.5rem;
}

.footer p span {
  font-weight: bold;
  color: var(--text-unfocus);
}

@media screen and (min-width: 960px) {
  .container:hover {
    box-shadow: 0px 0px 5px 0px var(--colour-extra1);
    background-color: var(--colour-extra1);
    color: var(--text-light);
    cursor: pointer;
    & .header p {
      color: var(--text-light);
    }
  }
}
