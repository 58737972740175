@import url('../var.css');

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  overflow-y: auto;
  width: 500px;
  height: 100%;
  background-color: var(--colour-tertiary);
  position: fixed;
  top: 0;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  transform: translateX(-500px);
  transition: transform 0.3s ease-in-out;
  z-index: 2;
}
.container.active {
  transform: translateX(0px);
}
.header {
  width: 100%;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  /* border-bottom: 1px solid var(--colour1); */
  color: var(--colour-primary);
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  p {
    flex: 1;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
  .closeform {
    position: absolute;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    top: 0.3rem;
    right: 0.3rem;
    cursor: pointer;
    background-color: var(--colour-tertiary);
    z-index: 1;
    & .icon {
      width: 1.5rem;
      height: 1.5rem;
      color: var(--colour-primary);
    }
  }
}
.main {
  padding: 1rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input,
  textarea,
  select {
    width: 100%;
    padding: 0.5rem;
    font-family: inherit;
    box-sizing: border-box;
    border: 1px solid var(--colour-primary);
    border-radius: 5px;
    color: var(--text-unfocus);
    &:focus {
      outline: none;
      border: 1px solid var(--colour-primary);
      box-shadow: 0px 0px 5px 0px var(--colour-primary);
      color: var(--text-focus);
    }
  }

  textarea {
    resize: none;
    height: 200px;
  }
  .date_type {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    input {
      width: auto;
    }
    select {
      width: auto;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .extra {
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    padding-left: 1rem;
    gap: 0.5rem;
    label {
      color: var(--colour-primary);
      left: -0.5rem;
    }
    .action {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      background-color: var(--colour-primary);
      font-family: inherit;
      box-sizing: border-box;
      border: 1px solid var(--colour-primary);
      border-radius: 5px;
      a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
      }
      input {
        padding: 0;
        background-color: inherit;
      }
      .icon {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--colour-extra1);
      }
    }
  }
}

.footer {
  padding: 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--colour1);
}
.iconedit {
  color: var(--colour-extra1);
  width: 1.5rem;
  height: 1.5rem;
}
.icondel {
  color: var(--colour-extra2);
  width: 1.5rem;
  height: 1.5rem;
}

@media (max-width: 500px) {
  .container {
    width: 100%;
    transform: translateX(-100%);
    .extra {
      padding-left: 0px;
      .action {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }
  }
}
